import { getProperty } from './property-util';
import VueI18n from 'vue-i18n';

export const loadScript = function (url: string, scriptId: string, callback?: (success: boolean) => void) {
    var loadTime = new Date();
    var doc = window.document;
    var scriptTag = doc.createElement('script');
    scriptTag.referrerPolicy = 'origin';
    scriptTag.type = 'application/javascript';
    scriptTag.id = scriptId;
    scriptTag.src = `${url}?timestamp=${loadTime.getTime()}`;

    var handler = function () {
        scriptTag.removeEventListener('load', handler);
        if (callback) {
            callback(true);
        }
    }

    var errhandler = function () {
        scriptTag.removeEventListener('error', errhandler);
        if (callback) {
            callback(false);
        }
    }

    scriptTag.addEventListener('load', handler);
    scriptTag.addEventListener('error', errhandler);

    if (!doc.getElementById(scriptId)) {
        doc.head.appendChild(scriptTag);
    } else {
        if (callback) {
            callback(true);
        }
    }
}