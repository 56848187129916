import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import * as accountTypes from '@account/store/types' 
import { mapGetters } from 'vuex'
import { loadScript } from '@appbase/util/script-loader'

@Component({
    computed: mapGetters({            
        user: accountTypes.USER
    })    
})
export default class PopupMaster  extends Vue {  
    user!: account.User;  
    scriptLoaded: boolean = false;

    created() {
        var _this = this;

        if((window as any).eumBoardIds) {
            this.scriptLoaded = true;
        } else {
            loadScript('/cm/gres/js/boardFieldSetting.js', 'eum-board-setting', function () {
                _this.scriptLoaded = true;
            });
        }        
    }
}